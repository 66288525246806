import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout';
import Seo from '../components/seo';

export default function Template({
                                   data, // this prop will be injected by the GraphQL query below.
                                 }) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <div className="blog-post-container">
      <div className="blog-post">
        <h1 style={{
            fontSize: `1.75rem`,
            marginBottom: `.5rem`
          }}
        >{frontmatter.title}</h1>
        <div
          style={{
            textAlign: `center`,
            marginBottom: `1rem`
          }}
        >{frontmatter.date}</div>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
